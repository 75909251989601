import React from 'react'

import { RecruitmentPreSign } from '../../components/auth/RecruitmentPreSign'

const IndexPage = () => {
  return <RecruitmentPreSign />
}

export default IndexPage
export const Head = () => <title>Datacapt - Recruitment Sign Up</title>
